import { useEffect, useState } from "preact/hooks";

interface DatabaseSelectorProps {
  currentPath: string;
  onSelect: (path: string) => void;
}

export default function DatabaseSelector({ currentPath, onSelect }: DatabaseSelectorProps) {
  const [databases, setDatabases] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const loadDatabases = async () => {
    try {
      setLoading(true);
      const baseDir = "/config/workspace/public/CardSets";
      const paths = ["Debug", "Release"].map(
        dir => `${baseDir}/${dir}/carddatabase`
      );
      setDatabases(paths);
    } catch (err) {
      setError("Failed to load databases");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDatabases();
  }, []);

  if (loading) {
    return <div>Loading databases...</div>;
  }

  if (error) {
    return <div class="text-red-500">{error}</div>;
  }

  return (
    <div class="mb-6">
      <label class="block text-sm font-medium text-gray-700 mb-2">
        Select Card Database
      </label>
      <select 
        class="w-full p-2 border rounded"
        value={currentPath}
        onChange={(e) => onSelect(e.currentTarget.value)}
      >
        {databases.map(db => (
          <option key={db} value={db}>
            {db.split('/').slice(-3).join('/')}
          </option>
        ))}
      </select>
    </div>
  );
}